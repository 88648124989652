export default function select() {
  const selects = document.querySelectorAll("select");

  selects.forEach((select) => {
    select.addEventListener("click", () => {
      select.classList.contains("active")
        ? removeActive(select)
        : addActive(select);
    });
  });

  window.addEventListener("click", (e) => {
    if (e.target.localName != "select") {
      clearActive();
    }
  });

  function addActive(item) {
    clearActive();
    item.classList.add("active");
  }
  function removeActive(item) {
    item.classList.remove("active");
  }

  function clearActive() {
    console.log("clear");
    selects.forEach((select) => removeActive(select));
  }
}
