export default function select() {
  const dates = document.querySelectorAll(".js-date-field");
  const btnReset = document.querySelector(".js-date-reset");

  dates.forEach((date) => {
    const placeholder = date.nextElementSibling;

    placeholder.addEventListener("click", () => {
      placeholder.style.display = "none";
    });

    date.addEventListener("focus", () => {
      placeholder.style.display = "none";
    });

    date.addEventListener("change", () => {
      console.log(date.value);
    });
  });

  btnReset.addEventListener("click", () => {
    dates.forEach((date) => {
      const placeholder = date.nextElementSibling;
      placeholder.style.display = "block";
    });
  });
}
